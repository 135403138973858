.root {
    z-index: 20 !important;
}
.paper {
    height: 50vh;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.header {
    margin-left: auto;
}
.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
}
.content h3 {
    font-size: 28px;
    color: #4B5EA3;
}

@media screen and (max-width: 568px) {
    .paper {
        height: 100vh !important;
    }
}

@media screen and (max-width: 468px) {
    .paper {
        padding: 16px;
    }
}