.main {
    width: 300px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #E86589;
    border-radius: 8px;
    box-shadow: 0 6px 0 0 #c44d6d;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.empty {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
}
.itemsList {
    width: 100%;
    background-color: white;
    padding: 9px;
    border-radius: 4px;
    box-sizing: border-box;
    max-height: 300px;
    overflow-y: scroll;
}
.total {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}
.total span {
    font-size: 16px;
}
.total p {
    font-size: 18px;
    font-weight: 700;
}
.navigateBut {
    margin-top: 12px !important;
    width: fit-content;
    background-color: #4B5EA3 !important;
    border-radius: 32px !important;
    color: white !important;
    font-family: 'Montserrat' !important;
    padding: 10px 25px !important;
    font-weight: 500 !important;
}
.navigateBut:hover {
    background-color: #42528d !important;
}