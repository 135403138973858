.main {
    display: flex;
    align-items: center;
    gap: 20px;
}
.burger {
    display: none;
}
.main button {
    font-size: 24px;
    padding: 10px;
}
.main button svg {
    color: #4B5EA3;
    font-size: 28px;
}
.tooltip {
    padding: 0 !important;
    max-width: unset !important;
    font-family: 'Montserrat' !important;
    font-size: inherit !important;
    background-color: transparent !important;
}
.arrow {
    color: #E86589 !important;
}
.searchMobile {
    display: none !important;
}

@media screen and (max-width: 1170px) {
    .searchMobile {
        display: block !important;
    }
    .burger {
        display: block;
    }
}

@media screen and (max-width: 568px) {
    .main {
        gap: 10px;
    }
    .main button svg {
        font-size: 24px;
    }
}

@media screen and (max-width: 375px) {
    .main button {
        padding: 5px;
    }
}