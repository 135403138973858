.main {
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 468px) {
    .sticky {
        position: fixed;
        z-index: 10;
    }
}