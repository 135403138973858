.main {
    max-width: 380px;
    width: 100%;
    position: relative;
    z-index: 8;
    transition-duration: .3s;
}
.active {
    max-width: 550px;
}
.searchBut {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    height: 51px !important;
    background-color: #9DC8CF !important;
    color: white !important;
    width: 50px !important;
    font-size: 20px !important;
}
.searchBut:hover {
    background-color: #77b2bb !important;
}

@media screen and (max-width: 1170px) {
    .main {
        width: 100%;
        max-width: unset;
        margin-top: auto;
        margin-bottom: 35%;
    }
    
}