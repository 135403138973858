.main {
    padding: 0 100px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 16;
}

@media screen and (max-width: 1440px) {
    .main {
        padding: 0 50px;
    }
}

@media screen and (max-width: 568px) {
    .main {
        padding: 0 15px;
    }
}

@media screen and (max-width: 568px) {
    .main {
        padding: 0 15px;
    }
}

