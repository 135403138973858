.main {
    bottom: 625px;
    position: absolute;
    right: 0px;
    z-index: 0;
}

@media screen and (max-width: 568px) {
    .main {
        display: none;
    }
}