.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.messageTotal {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 6px;
    margin-top: 15px;
    font-size: 14px;
    color: #37395C;
    border-bottom: 1px solid rgba(55, 57, 92, .1);
    padding-bottom: 15px;
}
.messageTotal a {
    text-decoration: none;
    color: #D56886;
    font-weight: 700;
}
.messageTotal a:hover {
    text-decoration: underline;
}
.messageTotal p {
    font-weight: 700;
    font-size: 18px;
    position: relative;
    top: 2px;
    color: #4B5EA3;
}
.navigateBut {
    margin-top: 24px !important;
    width: fit-content;
    background-color: #4B5EA3 !important;
    border-radius: 32px !important;
    color: white !important;
    font-family: 'Montserrat' !important;
    padding: 10px 25px !important;
}
.navigateBut:hover {
    background-color: #42528d !important;
}