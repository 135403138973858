.main {
    width: 100%;
    background-color: #4B5EA3;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.catalogContainer {
    width: 281px;
    display: flex;
    align-items: center;
    color: white;
    position: relative;
}
.catalogContainer p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-left: 12px;
}
.links {
    width: calc(100% - 281px);
    display: flex;
    align-items: center;
    gap: 22px;
}
.links a {
    color: white;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    transition-duration: .3s;
}
.handle {
    cursor: pointer;
    transition-duration: .3s;
    height: 100%;
}
.handle svg, .handle p {
    transition-duration: .3s;
}
.handle:hover p {
    transform: scale(1.05);
}
.handle:hover svg {
    transform: scale(1.2);
}
.catalogMenu {
    position: absolute;
    top: 35px;
    z-index: 8;
}

@media screen and (max-width: 1170px) {
    .catalogContainer {
        width: fit-content;
    }
    .links {
        width: 80%;
        justify-content: space-between;
        margin-left: 27px;
    }
    .catalogMenu {
        display: none;
    }
    .paper {
        width: 60%;
        background-color: #E86589 !important;
        display: flex;
        flex-direction: column;
        padding: 32px;
        box-sizing: border-box;
        position: relative;
    }
    .header {
        margin-left: auto;
    }
    .header button {
        color: white !important;
    }
}

@media screen and (max-width: 862px) {
    .catalogHeader {
        display: none;
    }
    .links {
        width: 100%;
    }
    .links a {
        font-size: 12px;        
    }
    .paper {
        width: 70%;
    }
}

@media screen and (max-width: 767px) {
    .main {
        display: none;
    }
}

@media screen and (max-width: 568px) {
    .paper {
        width: 90%;
        padding: 16px;
    }
}
