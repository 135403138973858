.main {
    width: 100%;
    min-height: 100vh;
    background-image: url("./Assets/main_bg.jpg");
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    position: relative;
}
.body {
    min-height: calc(100vh - 280px);
}
.footer {
    margin-top: auto;
}

.slick-list {
    /* height: 100% !important; */
    padding-right: 1px !important;
}
.sunImg {
    position: absolute;
    bottom: 700px;
    left: -100px;
    z-index: 0;
}

@media screen and (max-width: 568px) {
    .main {
        background-image: url("./Assets/main_bg_mobile.jpg");
    }

}