.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}
.content {
    width: 60%;
    height: 300px;
    background-color: #E86589;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem;
    box-sizing: border-box;
}
.text {
    width: 70%;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.text a {
    color: white;
    font-size: 14px;
}
.imgContainer {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.imgContainer img {
    width: 60%;
}

@media screen and (max-width: 1170px) {
    .content {
        width: 100%;
    }
}

@media screen and (max-width: 568px) {
    .content {
        height: fit-content;
    }
    .text {
        width: 100%;
    }
    .text h2 {
        font-size: 18px
    }
    .text p {
        font-size: 14px;
    }
    .imgContainer {
        display: none;
    }
    .imgContainer img {
        width: 100%;
    }
}