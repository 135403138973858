.main {
    width: 100%;
    background-color: #E6E8E8;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.side {
    height: 100%;
    display: flex;
    align-items: center;
}
.side + .side {
    justify-content: flex-end;
    gap: 35px;
}
.side a {
    color: #4B5EA3;
    text-decoration: none;
    font-size: 12px;
    font-weight: 700;
}
.side a:hover {
    text-decoration: underline;
}
.langBut {
    color: #93A6BD !important;
    font-size: 12px;
    font-weight: 700;
    height: 42px;
    border-radius: 0 !important;
}
.langBut:hover {
    text-decoration: underline;
}
.activeLang {
    color: #4B5EA3 !important;
    text-decoration: underline !important;
}

@media screen and (max-width: 1170px) {
    .main {
        display: none;
    }
}