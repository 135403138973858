.main {
    width: 50%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    padding: 25px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 26px -14px #e86589;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header h3 {
    color: #4B5EA3;
}
.header button {
    margin-left: auto;
}
.body {
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 10px;
    opacity: 0;
}
  
  /* Track */
.body::-webkit-scrollbar-track {
    background: transparent; 
    opacity: 0;
}
   
  /* Handle */
.body::-webkit-scrollbar-thumb {
    background-color: #E86589;
    border-radius: 8px;
}
  
  /* Handle on hover */
.body::-webkit-scrollbar-thumb:hover {
    opacity: 0;
}


@media screen and (max-width: 1170px) {
    .main {
        width: 80%;
    }
}


@media screen and (max-width: 568px) {
    .main {
        width: 95%;
        padding: 15px;
    }
}