.main {
    background-color: #37395C;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 318px;
}
.container {
    display: flex;
    flex-direction: column;
}
.top {
    display: flex;
    padding: 40px 0px;
    padding-bottom: 20px;
    box-sizing: border-box;
    justify-content: space-between;
}
.navBlock {
    display: flex;
    flex-direction: column;
}
.navBlock h4 {
    color: white;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 13px;
}
.navBlock a {
    font-weight: 400;
    font-size: 14px;
    color: white;
    text-decoration: none;
    margin-bottom: 10px;
}
.navBlock span {
    font-weight: 400;
    font-size: 14px;
    color: white;
    margin-bottom: 10px;
}
.navBlock a:hover {
    text-decoration: underline;
}
.infoBlock .navBlock a {
    font-size: 12px;
}
.infoBlock .navBlock + .navBlock {
    margin-top: 20px;
    font-size: 14px;
}
.bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.botLinks {
    display: flex;
    align-items: center;
    gap: 26px;
    margin-top: auto;
    padding-bottom: 10px;
    box-sizing: border-box;
}
.botLinks a {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: white;
    text-decoration: none;
}
.botLinks a:hover {
    text-decoration: underline;
}
.logos {
    display: flex;
    align-items: center;
    gap: 26px;
}
.logos img {
    width: 60px;
}

@media screen and (max-width: 1170px) {
    .main {
        height: fit-content;
    }
}

@media screen and (max-width: 768px) {
    .top {
        flex-wrap: wrap;
        row-gap: 24px;
    }
    .navBlock {
        width: fit-content;
    }
    .infoBlock {
        display: flex;
        gap: 15%;
        width: 100%;
        align-items: flex-start;
    }
    .infoBlock .navBlock + .navBlock {
        margin-top: 0px;
    }
}

@media screen and (max-width: 468px) {
    .top {
        flex-direction: column;
        align-items: center;
    }
    .navBlock {
        align-items: center;
    }
    .infoBlock {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    .bot {
        flex-direction: column;
        padding-bottom: 25px;
    }
    .botLinks {
        flex-direction: column;
        padding-bottom: 25px;
    }
    .logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}