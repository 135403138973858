.main {
    width: 100%;
    background-color: white;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
}
.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logoContainer {
    width: 281px;
}
.burger {
    display: none;
}
.logo {
    width: 150px;
}
.content {
    width: calc(100% - 281px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.phoneNumber {
    color: #4B5EA3;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
}
.phoneNumber:hover {
    text-decoration: underline;
}
.deskPhones {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.phones {
    display: none;
    flex-direction: column;
    gap: 8px;
}

@media screen and (max-width: 1200px) {
    .deskPhones {
        display: none;
    }
    .phones {
        display: flex;
    } 
}

@media screen and (max-width: 1170px) {
    .logoContainer {
        display: flex;
        align-items: center;
        width: fit-content;
    }
   
    .content {
        width: 100%;
    }
    .phoneNumber {
        margin-left: 27px;
    }
    .burger {
        display: block;
    }
    .desktop {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .logo {
        width: 120px;
    }
    .phoneNumber {
        display: none;
    }
    .content {
        justify-content: flex-end;
    }
}

@media screen and (max-width: 468px) {
    .main {
        height: 80px;
    }
}

@media screen and (max-width: 375px) {
    .main {
        height: 60px;
    }
    .logo {
        width: 100px;
    }
}