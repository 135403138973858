.menuItem {
    font-family: 'Montserrat';
    color: #4B5EA2;
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
}

@media screen and (max-width: 1170px) {
    .main {
        display: none;
    }
}