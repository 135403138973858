.main {
    position: absolute;
    width: 100%;
    bottom: -20px;
    padding-top: 50px;
    padding-bottom: 10px;
    border: 1px solid #9DC8CF;
    height: 0;
    transition-duration: .3s;
    z-index: -1;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    overflow: hidden;
    opacity: 0;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 130px;
    box-shadow: 0 0 10px -3px #9DC8CF;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
}
.container {
    gap: 8px;
    display: flex;
    width: 100%;
    margin-bottom: 16px;
}
.main a {
    color: #E86589;
    font-size: 14px;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
}
.main h5 {
    font-weight: 300;
    font-size: 14px;
    color: #37395C;
    margin-bottom: 8px;
}
.main h5::first-letter {
    text-transform: capitalize;
}
.items {
    width: 65%;
}
.other {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.active {
    height: 180px;
    bottom: -100px;
    opacity: 1;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    z-index: 1;
}
.filled {
    padding-top: 40px;
    height: 250px;
    max-height: 250px;
    bottom: -230px;
}
.empty {
    font-size: 14px;
    text-align: center;
    color: #37395C;
    width: 100%;
}
.onlyItems {
    width: 100%;
}


.otherItems {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.main::-webkit-scrollbar {
    width: 4px;
}
   
.main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #11121F;
}
   
.main::-webkit-scrollbar-thumb {
    background-color: #F04899;
    border-radius: 8px;
}
  
.main::-webkit-scrollbar-thumb:hover {
    background-color: #F04899;
    border-radius: 0;
}

@media screen and (max-width: 1170px) {
    .main {
        max-height: unset;
        top: 60%;
    }
    .active {
        max-height: 70vh;
        height: fit-content;
        /* min-height: 50px; */
    }
}

@media screen and (max-width: 568px) {
    .container {
        flex-direction: column;
    }
    .items, .other {
        width: 100%;
    }
}