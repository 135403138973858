.main {
    bottom: 585px;
    position: absolute;
    left: 25px;
    z-index: 0;
}

@media screen and (max-width: 568px) {
    .main {
        display: none;
    }
}