.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 16px;
}
.main p {
    font-size: 14px;
    line-height: 18px;
}
.activateBut {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    color: #E86589;
    font-family: 'Montserrat';
    font-size: 14px;
    margin-top: 16px;
}