.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;
    margin-top: 15px;
}
.form button[type="submit"] {
    width: 100%;
}
.submit {
    margin-top: 10px !important;
    width: 100%;
}
.modal {
    width: 35%;
    border: 3px solid #4B5EA3;
}
.forgot {
    margin-top: 15px !important;
    font-size: 12px !important;
    color: gray !important;
    text-decoration: underline;
    text-transform: initial !important;
    font-family: 'Montserrat' !important;
}
.message {
    color: #37395C;
    margin-top: 8px;
    font-size: 14px;
    margin-top: 16px;

}

@media screen and (max-width: 1024px) {
    .modal {
        width: 50%;
    }
}

@media screen and (max-width: 468px) {
    .modal {
        width: 90%;
    }
}

@media screen and (max-width: 350px) {
    .modal {
        width: 95%;
        padding: 16px;
    }
}