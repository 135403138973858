.main {
    width: 281px;
    height: 484px;
    max-height: 484px;
    background-color: #9DC8CF;
    padding: 17px 1%;
    box-sizing: border-box;
    overflow-y: auto;
}

.main::-webkit-scrollbar {
    width: 4px;
    opacity: 0;
  }
  
  /* Track */
.main::-webkit-scrollbar-track {
    background: transparent; 
    opacity: 0;
}
   
  /* Handle */
.main::-webkit-scrollbar-thumb {
    background-color: #4B5EA3;
    border-radius: 0px;
}

.childs {
    position: absolute;
    max-width: 50vw;
    width: 100%;
    height: fit-content;
    top: 0;
    left: 281px;
    background-color: white;
    z-index: 15;
    display: flex;
    flex-wrap: wrap;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 4px 0 8px -3px black;
    overflow: auto;
    max-height: 484px;
}
.mainList {
    position: relative;
	list-style-type: "▸";
	margin-left: 0;
	padding-left: 1.2em;
    color: white;
    margin: 0;
}
.mainList li {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding-left: 3%;
    margin-bottom: 17px;
    cursor: pointer;
    width: fit-content;
}
.mainList li:hover {
    text-decoration: underline;
}
.mainList li::first-letter {
    text-transform: capitalize;
}
