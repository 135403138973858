body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, p {
  margin-block-end: 0;
  margin-block-start: 0;
}
.MuiPickersDay-root.Mui-selected {
  background-color: #4B5EA3 !important;
}
.slick-track {
  margin-left: unset !important;
  margin-right: unset !important;
}

body::-webkit-scrollbar {
  width: 0px;
  opacity: 0;
}

/* Track */
body::-webkit-scrollbar-track {
  background: transparent; 
  opacity: 0;
}
 
/* Handle */
body::-webkit-scrollbar-thumb {
  background-color: #E86589;
  border-radius: 40px;
}

@media screen and (max-width: 768px) {
  .apexcharts-pie {
    transform: translateX(18%) !important;
  }
  .apexcharts-legend {
    top: 85px !important;
  }
}

@media screen and (max-width: 468px) {
  #SvgjsText1457 {
    font-size: 24px !important;
  }
}