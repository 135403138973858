.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.main p {
    width: fit-content;
    font-size: 16px;
    color: #37395C;
    text-align: center;
}